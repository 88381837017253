import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import FormWrapper from "../FormWrapper";
import FormGroup from "../FormGroup";
import { Field } from "components/Design/Input/Field";
import Textarea from "components/Design/Input/Textarea";
import Input from "components/Design/Input/Input";
import { ContactInformation } from "../CommonField";
import { JA_INQUIRY_PLACEHOLDER } from "store/constants/formPlaceholder";

const JakartaGeneralInquiry = () => {
  const { language } = useI18next();
  const { t } = useTranslation("support");

  const titleOption = [
    { label: t("Mr"), value: "Mr" },
    { label: t("Ms"), value: "Ms" },
  ];

  return (
    <FormWrapper title={t("Jakarta General Inquiry")}>
      <FormGroup>
        <Field name="subject" label={t("Subject")}>
          <Input />
        </Field>

        <Field name="inquiry" label={t("Inquiry")}>
          <Textarea
            className={language === "ja" && "placeholder:pt-16"}
            placeholder={language === "ja" && JA_INQUIRY_PLACEHOLDER}
          />
        </Field>
      </FormGroup>

      <ContactInformation t={t} titleOption={titleOption} />
    </FormWrapper>
  );
};

export default JakartaGeneralInquiry;
